import axios from "@/libs/axios";


export async function index(query) {
    try {
        const { data } = await axios.get("/api/v1/invoices", { params: query })
        return { data }
    } catch (error) {
        return { error }
    }
}

