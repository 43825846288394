<template>
  <div>
    <go-back :title="$t('menu.billing')"/>

    <b-row v-if="!subs.preLoading">
      <b-col cols="12">
        <h3>Active Subscriptions</h3>
      </b-col>
      <b-col
        sm="6"
        md="4"
        lg="4"
        v-for="(sub, index) in subs.data"
        :key="index"
      >
        <plan-card :plan="sub" :loading="subs.loading" @refresh="fetchPlans" />
      </b-col>
    </b-row>

    <b-card no-body>
      <div class="m-2">
        <b-card-title>
          {{ $t("invoice.title") }}
        </b-card-title>
        <data-table :fetchData="fetchData" :columns="columns">
          <template slot="row" slot-scope="props">
            <span v-if="props.column.field === 'created'">
              {{ $d(new Date(props.row.created * 1000), "full", "en") }}
            </span>

            <span v-else-if="props.column.field === 'status'">
              <b-badge
                :title="$t('invoice.status.' + props.row.status)"
                v-b-tooltip.hover="{
                  variant: statusIconAndColor(props.row.status).color,
                }"
                :variant="statusIconAndColor(props.row.status).color"
                class="status-badge w-100"
              >
                <feather-icon
                  :icon="statusIconAndColor(props.row.status).icon"
                />
                {{ $t("invoice.status." + props.row.status) }}
              </b-badge>
            </span>

            <span
              v-else-if="props.column.field === 'total'"
              class="payment-text"
            >
              <template v-if="props.row.subtotal != props.row.total">
                <span class="linethrough text-mutext"
                  >${{ props.row.subtotal }}</span
                >
                / <span>${{ props.row.total }}</span>
              </template>
              <template v-else> ${{ props.row.total / 100 }} </template>
            </span>

            <span v-else-if="props.column.field == 'action'">
              <action-button
                icon="EyeIcon"
                :title="$t('common.actions.show')"
                @click="() => downloadInvoice(props.row.invoice_url)"
              />
            </span>

            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        </data-table>
      </div>
    </b-card>
  </div>
</template>

<script>
import DataTable from "@/components/DataTable/DataTable.vue";
import { index } from "@/api/invoices.api";
import { show } from "@/api/subscriptions.api";
import ActionButton from "@/components/DataTable/ActionButton.vue";
import GoBack from "@/components/Common/GoBack.vue";
import { isFuture, fromUnixTime } from "date-fns";
import Spinner from "@/components/Spinner.vue";
import PlanCard from "./components/PlanCard.vue";

export default {
  components: { DataTable, ActionButton, GoBack, Spinner, PlanCard },
  data() {
    return {
      subs: {
        preLoading: true,
        loading: false,
        data: [],
      },
      columns: [
        { label: this.$t("fields.date"), field: "created", sortable: false },
        {
          label: this.$t("fields.status"),
          field: "status",
          width: "120px",
          sortable: false,
        },
        {
          label: this.$t("fields.total"),
          field: "total",
          width: "140px",
          sortable: false,
        },

        {
          label: this.$t("fields.actions"),
          field: "action",
          sortable: false,
          width: "140px",
        },
      ],
      isFuture,
      fromUnixTime,
    };
  },
  methods: {
    async fetchData() {
      const { data } = await index();
      return { data, meta: { total: data.length } };
    },
    statusIconAndColor(status) {
      return {
        paid: {
          color: "light-success",
          icon: "CheckCircleIcon",
        },
        draft: {
          color: "light-primary",
          icon: "SaveIcon",
        },
        open: {
          color: "light-warning",
          icon: "SendIcon",
        },
        uncollectible: {
          color: "light-danger",
          icon: "AlertCircleIcon",
        },
        void: {
          color: "light-secondary",
          icon: "XCircleIcon",
        },
      }[status];
    },
    async downloadInvoice(url) {
      const a = document.createElement("a");
      a.target = "_blank";
      a.href = url;
      a.click();
    },
    async fetchPlans() {
      if (this.subs.loading) return;
      this.subs.loading = true;
      const { data } = await show();
      this.subs.loading = false;
      this.subs.preLoading = false;
      this.subs.data = data;
    },
  },
  mounted() {
    this.fetchPlans();
  },
};
</script>

<style lang="scss">
.status-badge {
  border-radius: 9999px;
  padding: 0;
  padding: 0.5rem;
  margin: 0;

  svg {
    width: 1rem;
    height: 1rem;
    margin: auto;
  }
}
.payment-text {
  font-size: 1rem;
}

.tag-box {
  gap: 0.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
</style>