<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>
        {{ plan.plan.title }} <spinner v-if="loading" />
      </b-card-title>

      <!-- CARD DROPDOWN -->
      <b-dropdown
        v-if="!plan.is_trial"
        variant="link"
        no-caret
        class="d-flex align-items-center dropdown-user-link"
        toggle-class="p-0"
        right
      >
        <template #button-content>
          <feather-icon
            icon="MoreVerticalIcon"
            size="18"
            class="text-body cursor-pointer"
          />
        </template>

        <b-dropdown-item
          v-if="!plan.ends_at"
          @click="() => cancel(plan.stripe_id)"
        >
          <feather-icon icon="XIcon" class="mr-50 h-full" size="16" />
          <span>
            {{ $t("subscription.actions.cancel") }}
          </span>
        </b-dropdown-item>
        <b-dropdown-item
          v-if="plan.ends_at && isFuture(fromUnixTime(plan.ends_at))"
          @click="() => restore(plan.stripe_id)"
        >
          <feather-icon icon="RefreshCwIcon" class="mr-50 h-full" size="16" />
          <span>
            {{ $t("subscription.actions.restore") }}
          </span>
        </b-dropdown-item>
        <b-dropdown-item
          v-if="hasPendingPayment"
          @click="() => cancelUpgrade(plan.stripe_id)"
        >
          <feather-icon icon="CreditCardIcon" class="mr-50 h-full" size="16" />
          {{ $t("subscription.actions.cancel-upgrade") }}
        </b-dropdown-item>
      </b-dropdown>
      <!-- CARD DROPDOWN -->
    </b-card-header>
    <b-card-body>
      <!-- FEATURES -->
      <span
        v-for="feature in plan.plan.features"
        :key="feature.title"
        class="d-block"
        style="padding-bottom: 8px"
      >
        {{ feature.title }}
      </span>

      <!-- PRICE -->
      <span v-if="!plan.is_trial"> ${{ plan.plan.price.month.amount }}</span>
      <div class="d-flex flex-row tag-box">
        <span v-if="plan.ends_at">
          <b-badge
            variant="light-warning"
            pill
            style="font-size: 1rem; padding: 0.5rem"
          >
            {{ $t("subscription.cancelled") }}
          </b-badge>
        </span>

        <span v-if="plan.is_trial">
          <b-badge
            variant="light-danger"
            pill
            style="font-size: 1rem; padding: 0.5rem"
          >
            {{ $t("subscription.trial") }}
          </b-badge>
        </span>

        <span v-if="plan.stripe_status == 'past_due'">
          <b-badge
            variant="light-danger"
            pill
            style="font-size: 1rem; padding: 0.5rem"
            v-b-tooltip.hover.danger
            :title="$t('subscriptions.past_due_message')"
          >
            {{ $t("subscription.past_due") }}
          </b-badge>
        </span>

        <span v-if="hasPendingPayment">
          <b-badge
            variant="light-danger"
            pill
            style="font-size: 1rem; padding: 0.5rem"
            v-b-tooltip.hover.danger
            :title="$t('subscriptions.past_due_message')"
          >
            {{ $t("subscription.pending_payment") }}
          </b-badge>
        </span>
      </div>
      <span v-if="plan.ends_at" class="d-block">
        <strong>{{ $t("subscription.cancelled_at") }}: </strong>
        {{ $d(new Date(plan.ends_at * 1000), "long", "en") }}
      </span>
    </b-card-body>
  </b-card>
</template>

<script>
import { isFuture, fromUnixTime } from "date-fns";
import { cancel, cancelUpgrade, restore } from "@/api/subscriptions.api";

export default {
  props: ["plan", "loading"],
  data() {
    return {
      isFuture,
      fromUnixTime,
    };
  },
  computed: {
    hasPendingPayment() {
      return (
        this.plan.properties && this.plan.properties["has_pending_payment"]
      );
    },
  },
  methods: {
    async cancel(id) {
      const { data, error } = await cancel(id);
      this.$emit("refresh");
    },
    async restore(id) {
      const { data, error } = await restore(id);
      this.$emit("refresh");
    },
    async cancelUpgrade(id) {
      const { data, error } = await cancelUpgrade(id);

      setTimeout(() => {
        this.$emit("refresh");
        this.$auth.fetch();
      }, 2000);
    },
  },
};
</script>

<style>
</style>